import React, { useState } from "react";

//Componentes de material-UI
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    IconButton,
} from "@mui/material";

const DocsVersion = () => {
    const [openDocsVersion, setOpenDocsVersion] = useState(false);
    const handleOpen = () => {
        setOpenDocsVersion(true);
    };
    const handleClose = () => {
        setOpenDocsVersion(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Typography variant="caption" sx={{ color: "#FFFFFF" }}>
                    version 1.1.0
                </Typography>
            </IconButton>

            <Dialog
                open={openDocsVersion}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Versionamiento del aplicativo"}
                </DialogTitle>
                <DialogContent>
                    <Typography>Versión 1.1.0</Typography>
                    <List component="nav">
                        <ListItem>
                            <ListItemText secondary="Adición de roles a la aplicación" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.0.0</Typography>
                    <List component="nav">
                        <ListItem>
                            <ListItemText secondary="Desplegue de la aplicación solo con la funcionalidad Sala de Trofeos" />
                        </ListItem>
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocsVersion;
