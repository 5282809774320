import React, {
    memo,
    useCallback,
    Fragment,
    useContext,
    useState,
    useEffect,
} from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//Librerias
import { Link, Outlet, useNavigate } from "react-router-dom";
//Componentes Material-UI
import {
    Toolbar,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Hidden,
    Typography,
    Box,
    Container,
    CssBaseline
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar"

//Iconos Material-UI
import { Menu as MenuIcon, PersonOutline as PersonIcon } from "@mui/icons-material";

//Style Material-UI
import { createStyles, styled } from "@mui/material/styles";

//Mis componentes
import MenuDrawer from "./drawerMenu";

//Estilos de mi componente
const MenuStyle = createStyles(() => ({
    avatarInterno: {
        width: "90px",
        height: "90px",
    },
    avatar: {
        marginLeft: "15px",
        width: "30px",
        height: "30px",
    },
}));

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        width: "80vh",
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            [theme.breakpoints.up("sm")]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0 ,
            },
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },

    }),
}));

/**
 * Componente con el cual se renderiza el menu de navegación de mi aplicativo donde se controla los permisos
 * @type {Function} menu de mi aplicativo
 * @param {children} children recibe el hijo para poder renderizar
 * @returns retorna el container donde se renderizaran los diferentes componentes de mi aplicativo
 */

const ContainerMenu = ({ children }) => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { strData, cerrarSesion } = useContext(ContextCreate);

    //===============================================================================================================================================
    //========================================== States =============================================================================================
    //===============================================================================================================================================
    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuProfile, setOpenMenuProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [selectedList, setSelectedList] = useState(0);
    const [isAdmin, setIsAdmin] = useState();
    //===============================================================================================================================================
    //========================================== Hooks ==============================================================================================
    //===============================================================================================================================================
    let history = useNavigate();
    //===============================================================================================================================================
    //========================================== Funciones ============================================================================================
    //===============================================================================================================================================

    const toggleDrawer = useCallback(() => {
        setOpenMenu(!openMenu);
    }, [openMenu]);

    const toggleProfile = useCallback(() => {
        setOpenMenuProfile(!openMenuProfile);
    }, [openMenuProfile]);

    const handleProfile = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);

    const cambiarInfo = useCallback(
        (url, index) => {
            history(url);
            setSelectedList(index);
        },
        [history]
    );

    useEffect(() => {
        if (strData.strRolApp) {
            for (let i = 0; i < strData.strRolApp.length; i++) {
                if (strData.strRolApp[i].strNombre === "ADMINISTRADOR") {
                    setIsAdmin(true);
                    return;
                }
            }
        }
    }, [strData]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const nombreUsuario = `${strData.strNombre} ${strData.strApellidos}`;
    const classes = MenuStyle();

    return (
        <Fragment>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" open={openMenu} >
                    <Toolbar style={{ minHeight: 50 }}>
                        <Box flexGrow={1} display="flex" alignItems="center">
                            {!openMenu ? (
                                <IconButton onClick={(e) => toggleDrawer(e, true)}>
                                    <MenuIcon sx={{ color: "#ffffff" }} />
                                </IconButton>
                            ) : null}
                            <Typography variant="h6">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                    to="/reconocimientos/inicio"
                                    onClick={() =>
                                        cambiarInfo("/reconocimientos/inicio", 0)
                                    }
                                >
                                    De 10m a 10X
                                </Link>
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Hidden smDown>
                                <Typography variant="subtitle1">
                                    {strData ? strData.strUsuario : "undefined"}
                                </Typography>
                            </Hidden>
                            <IconButton
                                sx={{ padding: "5px" }}
                                onClick={(e) => {
                                    toggleProfile();
                                    handleProfile(e);
                                }}
                            >
                                <Avatar sx={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenuProfile}
                                onClose={toggleProfile}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding="0px 15px"
                                >
                                    <Box>
                                        <Avatar sx={classes.avatarInterno}>
                                            <PersonIcon
                                                sx={classes.avatarInterno}
                                            />
                                        </Avatar>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                    >
                                        <Box padding="6px 16px" maxWidth="200px">
                                            <Typography noWrap>
                                                <b>
                                                    {strData
                                                        ? nombreUsuario
                                                        : "undefined"}
                                                </b>
                                            </Typography>
                                            <Typography variant="caption" noWrap>
                                                {strData ? strData.strEmail : undefined}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <MenuItem onClick={toggleProfile}>
                                                <a
                                                    href="https://choucairtesting-my.sharepoint.com/person.aspx"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "black",
                                                    }}
                                                    target="_blank"
                                                >
                                                    Mi perfil de Office 365
                                                </a>
                                            </MenuItem>
                                            <MenuItem onClick={() => cerrarSesion()}>
                                                Cerrar sesión
                                            </MenuItem>
                                        </Box>
                                    </Box>
                                </Box>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <MenuDrawer
                    isAdmin={isAdmin}
                    open={openMenu}
                    drawerWidth={drawerWidth}
                    toggleDrawer={toggleDrawer}
                    cambiarInfo={cambiarInfo}
                    selectedList={selectedList}
                />
                <Main open={openMenu}>
                    <Container sx={{ paddingTop: "50px" }}>
                        {children}
                        <Outlet />
                    </Container>
                </Main>
            </Box>
        </Fragment>
    );
};

export default memo(ContainerMenu);
