import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./app";

const app = document.getElementById("root")

const root = createRoot(app)

root.render(
    <>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </>
)

