import React, { memo } from "react";

//Librerias
import { Link } from "react-router-dom";

//componentes Material-UI
import {
    Drawer,
    Divider,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import { Box } from "@mui/system";

//Style Material-UI
import { createStyles, styled } from "@mui/material/styles";
//Iconos de Material UI
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

//Mis componentes
import ListAdmin from "./ListUser/AdminList";
import Version from "./docsVersion";

//Imagenes
import LogoCH from "../../static/images/logoChoucair.jpg";

const drawerStyles = createStyles(() => ({
    box: {
        height: "inherit",
        width: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "black"
    },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight:50,
    background: "black",
    color: "white"
}));

/**
 *
 * @param {open} open es un booleano que controla si se abre el menuDrawer
 * @param {toggleDrawer} toggleDrawer es una funcion que me recibe dos parametros un event y un booleano
 *
 */

const MenuDrawer = ({ open, toggleDrawer, cambiarInfo, selectedList, isAdmin, drawerWidth }) => {
    //===============================================================================================================================================
    //========================================== Declaracion de estados =============================================================================
    //===============================================================================================================================================

    //===============================================================================================================================================
    //========================================== Hooks personalizados ===============================================================================
    //===============================================================================================================================================
    const theme = useTheme();
    console.log(theme.mixins.toolbar)
    const movil = useMediaQuery(theme.breakpoints.down("sm"));

    //===============================================================================================================================================
    //============================================= UseEffect  ======================================================================================
    //===============================================================================================================================================

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const classes = drawerStyles();
    return (
        <Drawer
            sx={{
                width: open ? drawerWidth : 0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    border: "none",
                    boxSizing: 'border-box',
                },
            }}
            variant={movil ? "temporary" : "persistent"}
            anchor="left"
            open={open}
        >
            <DrawerHeader sx={{ minHeight: "55px" }}>
                <IconButton onClick={toggleDrawer} >
                    {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: "white" }} /> : null}
                </IconButton>
            </DrawerHeader>

            <Divider />
            <Box sx={classes.box}>
                <Box sx={{ flexGrow: 1, minWidth: "100%" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Link
                            to="/reconocimientos/inicio"
                            onClick={(e) => {
                                if (movil) {
                                    toggleDrawer();
                                }
                                cambiarInfo("/reconocimientos/inicio", 0);
                            }}
                        >
                            <img
                                src={LogoCH}
                                alt="Icono choucair"
                                style={{
                                    width: "150px",
                                    height: "45px",
                                    marginTop: "20px",
                                }}
                            />
                        </Link>
                    </Box>
                    <ListAdmin
                        isAdmin={isAdmin}
                        index={selectedList}
                        toggleDrawer={toggleDrawer}
                        movil={movil}
                        cambiarInfo={cambiarInfo}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Version />
                </Box>
            </Box>
        </Drawer>

    );
};

export default memo(MenuDrawer);
