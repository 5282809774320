import React from "react";

//Librerias
import { Route, Routes } from "react-router-dom";

//Middlewares

//Componentes
import AuthState from "./Auth/Context";
import Login from "./containers/Login";
import AuthRouter from "./middleware/authRouter";
import Menu from "./containers/Menu";
import Inicio from "./containers/Home";
import Trofeos from "./components/SalaTrofeos"

const RouterReconocimientos = () => {
    return (
        <AuthState>
            <Routes>
                <Route path="/" exact element={<Login />} />

                <Route path="/reconocimientos" element={<AuthRouter />}>
                    <Route path="/reconocimientos" element={<Menu />} >

                        <Route
                            path="/reconocimientos/inicio"
                            Component={Inicio}
                            exact
                        />
                        <Route
                            path="/reconocimientos/salatrofeos"
                            element={<Trofeos />}
                            exact
                        />
                    </Route>
                </Route>
            </Routes>
        </AuthState>
    );
};

export default RouterReconocimientos;
