import React, { useState, useEffect, useCallback, useContext} from "react";

//Context
import { ContextCreate } from "../../Auth/Context";

//Librerias
import { useForm, Controller } from "react-hook-form";
import Player from "react-lottie-player";
import { Navigate } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import Cookies from "js-cookie";

//Componentes Material-UI
import {
    Grid,
    Box,
    Paper,
    TextField,
    InputAdornment,
    Container,
    Typography,
    Button,
    LinearProgress,
    CircularProgress,
    useTheme
} from "@mui/material";

import { toast } from "react-hot-toast";
import { AccountCircle as AccountCircleIcon, Lock as LockIcon } from "@mui/icons-material";

//Estilos de Material UI
import { createStyles } from "@mui/material/styles";

//Imagenes
import LoginWall from "../../static/images/loginWall.jpg";
import LogoCH from "../../static/images/LogoCH.png";

const containerLoginStyle = createStyles((theme) => ({
    linearProgress: {
        position: "absolute",
        width: "100%",
        borderRadius: "10px 10px 0 0",
    },
    button: {
        position: "relative",
    },
    circularProgress: {
        position: "absolute",
        margin: "auto",
    },
    checked: {
        fontSize: "12px",
    },
    copyright: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
    },
    gridOne: {
        padding: theme.spacing(2),
        position: "relative",
    },
    gridTwo: {
        backgroundColor: "#174060",
        borderRadius: "0 7px 7px 0",
    },
    player: {
        position: "relative",
        width: "750px",
        margin: "auto",
    },
    backgrounLogin: {
        backgroundImage: `url(${LoginWall})`,
        height: "100vh",
        width: "100vw",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
    },
    container: {
        display: "relative",
        height: "100vh",
    },
    paper: {
        position: "relative",
        borderRadius: "7px",
    },
}));
const ContainerLogin = () => {
    //========================================================================================================================
    //========================================= Context  =====================================================================
    //========================================================================================================================
    const { token, iniciarSesion } = useContext(ContextCreate);
    //========================================================================================================================
    //========================================= hooks personalizados  ========================================================
    //========================================================================================================================
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({ mode: "onChange" });
    const theme = useTheme()
    //=========================================================================================================================
    //========================================== Referencias ==================================================================
    //=========================================================================================================================

    //========================================================================================================================
    //========================================= States  ======================================================================
    //========================================================================================================================
    const [loading, setLoading] = useState(false);
    const [flagSubmit, setFlagSubmit] = useState(false);
    const [data, setData] = useState({
        strUser: "",
        strPass: "",
        strApp: "RECONOCIMIENTOS",
    });

    //========================================================================================================================
    //========================================= Funciones  ===================================================================
    //========================================================================================================================

    const onSubmitData = (data) => {
        setData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setFlagSubmit(true);
    };

    const submitData = useCallback(
        async (signalSubmitData) => {
            setLoading(true);
            setFlagSubmit(false);

            await axios(
                {
                    method: "POST",
                    baseURL: `${process.env.REACT_APP_PROTOCOL_BACKEND_DATALAKESERVICES}://${process.env.REACT_APP_HOST_BACKEND_DATALAKESERVICES}${process.env.REACT_APP_PORT_BACKEND_DATALAKESERVICES}`,
                    url: `${process.env.REACT_APP_API_LOGIN_DATALAKESERVICES}`,
                    data,
                },
                {
                    cancelToken: signalSubmitData.token,
                }
            )
                .then((res) => {
                    if (res.data.error) {
                        throw new Error(res.data.msg);
                    }
                    setLoading(false);
                    iniciarSesion(res.data.data);
                    if (
                        !process.env.REACT_APP_NODE_ENV ||
                        process.env.REACT_APP_NODE_ENV !== "production"
                    ) {
                        Cookies.set("token", res.data.data);
                    } else {
                        Cookies.set("token", res.data.data, {
                            domain: ".choucairtesting.com",
                        });
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        let msg;

                        if (error.response) {
                            msg = error.response.data.msg;
                        } else if (error.request) {
                            msg = error.message;
                        } else {
                            msg = error.message;
                        }

                        console.error(error);
                        setLoading(false);
                        toast.error(msg);
                    }
                });
        },
        [data, iniciarSesion]
    );
    //===============================================================================================================================================
    //========================================== useEffects =========================================================================================
    //===============================================================================================================================================
    useEffect(() => {
        let signalSubmitData = axios.CancelToken.source();
        if (flagSubmit) {
            submitData(signalSubmitData);
        }
        return () => {
            signalSubmitData.cancel("Petición abortada.");
        };
    }, [flagSubmit, submitData]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    //#NOTE: variable que se utiliza para aplicar estilos personalizados de material ui
    const classes = containerLoginStyle(theme);

    if (token) {
        return <Navigate to="/reconocimientos/inicio"/>;
    }

    return (
        <Box sx={classes.backgrounLogin}>
            <Container sx={classes.container}>
                <Box
                    sx={{
                        height: "inherit",
                        width: "inherit",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Paper elevation={3} sx={classes.paper}>
                        {loading ? (
                            <LinearProgress sx={classes.linearProgress} />
                        ) : null}
                        <Grid container direction="row">
                            <Grid item xs={12} md={4} sx={classes.gridOne}>
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    component="form"
                                    onSubmit={handleSubmit(onSubmitData)}
                                    noValidate
                                >
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img src={LogoCH} alt="logo choucair" />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            align="center"
                                        >
                                            De 10m a 10X
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            align="center"
                                        >
                                            Reconoce la labor de tus colaboradores
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            id="txt_strUser"
                                            name="strUser"
                                            defaultValue={data.strUser}
                                            render={({
                                                field: { name, value, onChange },
                                            }) => (
                                                <TextField
                                                    label="Usuario"
                                                    helperText={
                                                        errors?.strUser?.message ||
                                                        "Digita tu usuario corporativo, Ejemplo: soporteti"
                                                    }
                                                    error={errors?.strUser ? true : false}
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    autoComplete="on"
                                                    disabled={loading}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircleIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            )}
                                            control={control}
                                            rules={{
                                                required:
                                                    "Por favor digita tu usuario corporativo, Ejemplo: soporteti",
                                                validate: (value) => {
                                                    if (
                                                        !validator.isLength(value, {
                                                            min: 0,
                                                            max: 40,
                                                        })
                                                    ) {
                                                        return "El campo de usuario sobrepasa el limite de longitud permitida.";
                                                    }

                                                    if (validator.isEmail(value)) {
                                                        return "Por favor utiliza solo tu usuario corporativo excluyendo el dominio @choucairtesting.com.";
                                                    }
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            id="txt_strPass"
                                            name="strPass"
                                            defaultValue={data.strPass}
                                            render={({
                                                field: { name, value, onChange },
                                            }) => (
                                                <TextField
                                                    label="Contraseña"
                                                    type="password"
                                                    helperText={
                                                        errors?.strPass?.message ||
                                                        "Digita tu contraseña"
                                                    }
                                                    error={errors.strPass ? true : false}
                                                    autoComplete="on"
                                                    variant="standard"
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    disabled={loading}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            )}
                                            control={control}
                                            rules={{
                                                required:
                                                    "Por favor digita tu contraseña",
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            type="submit"
                                            disabled={loading}
                                            sx={classes.button}
                                        >
                                            ingresar
                                            {loading ? (
                                                <CircularProgress
                                                    sx={classes.circularProgress}
                                                    size={24}
                                                />
                                            ) : null}
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            align="center"
                                        >
                                            <a
                                                href="https://corporativo.choucairtesting.com/mesadeayuda/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: "black",
                                                }}
                                            >
                                                ¿Olvidaste tu contraseña?
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Box sx={classes.copyright}>
                                    <Box>
                                        <Typography variant="caption">
                                            Todos los derechos reservados - 2021 ©
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={8} sx={classes.gridTwo}>
                                <Box sx={classes.player}>
                                    <Player
                                        play
                                        loop
                                        animationData={require("../../static/json/teamlogin.json")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};

export default (ContainerLogin);
