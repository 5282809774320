import React from "react";

//Librerias
import { Link as RouterLink } from "react-router-dom";

//Componentes de Material UI
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

//Iconos de Material UI
import { SettingsApplications as AdminIcon } from "@mui/icons-material";

//Estilos de Material UI
import { createStyles } from "@mui/material/styles";
import { Box } from "@mui/system";

const listMenuUserStyles = createStyles(() => ({
    selected: {
        "&.Mui-selected": {
            backgroundColor: "#606060"
        },
        "&.Mui-focusVisible": {
            backgroundColor: "#606060"
        },
        ":hover": {
            backgroundColor: "#606060"
        }
    },
}));

const GetListAdmin = ({ toggleDrawer, movil, index, cambiarInfo, isAdmin }) => {
    const classes = listMenuUserStyles();
    return (
        <Box>
            <List component="nav">
                <ListItemButton
                    component={RouterLink}
                    to="/reconocimientos/admin"
                    disabled
                    selected={window.location.pathname === "/reconocimientos/admin"}
                    sx={classes.selected}
                    onClick={(e) => {
                        if (movil) {
                            toggleDrawer(e, false);
                        }
                        cambiarInfo("/reconcimientos/admin", 1);
                    }}
                >
                    <ListItemIcon>
                        <AdminIcon
                            sx={isAdmin ? { color: "white" } : { color: "black" }}
                            fontSize="large"
                        />
                    </ListItemIcon>
                    <ListItemText
                        sx={isAdmin ? { color: "white" } : { color: "black" }}
                        primary="Gestionar Reconocimientos"
                    />
                </ListItemButton>
            </List>
        </Box>
    );
};

export default GetListAdmin;
